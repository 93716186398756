.img-wrapper {
	margin-right: 15px;
	width: 30px;
	height: 30px;

	.image {
		width: 100%;
		height: 100%;
	}
}

.org-icon {
	mix-blend-mode: darken;
	cursor: pointer;
}
